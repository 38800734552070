<template>
    <section>
        <div class="row mx-0">
            <div class="col-7 px-0 alto overflow-auto custom-scroll border-right">
                <div class="row mx-0 px-3 pl-5 border-bottom border-right align-items-center" style="height:48px;">
                    <p class="f-600 text-general">{{ detalle.nombre }}</p>
                    <el-tooltip v-if="detalle.id !== null && $can('botones_promociones_gestionar')" class="item" effect="light" content="Eliminar promoción" placement="bottom">
                        <div class="btn-action d-middle text-general cr-pointer mx-1 ml-auto border" @click="eliminarPromo">
                            <i class="icon-trash-empty f-22" />
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="detalle.id !== null && $can('botones_promociones_gestionar')" class="item" effect="light" content="Editar promoción" placement="bottom">
                        <div class="btn-action d-middle text-general cr-pointer mx-1 border" @click="editarPromocion">
                            <i class="icon-pencil-outline f-22" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="p-3 f-15 overflow-auto custom-scroll" style="height: calc(100% - 55px);">
                    <content-loader v-if="loading || promociones.length===0" />
                    <sinDatos v-if="detalle.id === null" icon="sale" mensaje="Seleccione una promoción." />
                    <div v-else>
                        <div class="row mx-0">
                            <img class="shadow br-10 w-100" height="160" :src="detalle.imagen_show" alt="" />
                        </div>
                        <div class="row mx-0 my-3 justify-content-center text-center text-general f-500 f-22">
                            {{ detalle.nombre }}
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col-12 px-0 f-17 text-general">
                                <p class="f-500">Condiciones: </p>
                            </div>
                            <div class="col-12 px-0 text-general my-2 rompe-palabras f-14">
                                <p> {{ detalle.condiciones }}</p>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col-12 px-0">
                                <p class="f-17 text-general">
                                    <span class="f-500">Tipo de promoción:</span>
                                    {{ detalle.tipoShow }}
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 f-500 f-17 text-general">
                            Categorías (Productos añadidos por las tiendas):
                        </div>
                        <div class="row mx-0 my-2 f-17">
                            <div v-for="(info, idx) in detalle.promo_categorias" :key="idx" class="bg-light-f5 br-20 px-3 border py-1 m-2">
                                {{ info.nombre }}({{ info.cant_productos }})
                            </div>
                        </div>
                        <div class="row mx-0 my-2 f-500 text-general">
                            Previsualización ejemplo
                        </div>
                        <div class="row mx-0 my-2">
                            <cardProductoVer :info="detalle" class="p-2 mx-2 f-15 overflow-auto custom-scroll" style="max-height: calc(100% - 80px);" />
                            <!-- <cardProducto :diseno="detalle" :ver="true" /> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 px-0 alto overflow-auto custom-scroll">
                <cedis />
                <content-loader v-if="loading || promociones.length===0" />
            </div>
        </div>
        <modalEditarPromo ref="modalEditarPromo" :editar-prom="editarProp" @actualizar="actualizarDetalle" />
        <modalEliminar ref="modalEliminarPromo" titulo="Eliminar promoción" mensaje="¿Desea eliminar la promoción" @eliminar="eliminar" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Promocion from '~/services/promociones/promociones'

export default {
    name: 'DetallePromocion',
    components: {
        cedis: () => import('./cedis'),
        modalEditarPromo: () => import('../partials/modalCrearPromocion'),
    },
    data(){
        return {
            buscar: '',
            loading: false,
            editarProp: false,
        }
    },
    computed:{
        ...mapGetters({
            detalle: 'promociones/promociones/detalle',
            cedis: 'promociones/promociones/cedis',
            promociones: 'promociones/promociones/promociones',
        }),

    },
    methods: {
        editarPromocion(){
            this.$refs.modalEditarPromo.toggle(this.detalle)
            this.editarProp = true
        },
        eliminarPromo(){
            this.$refs.modalEliminarPromo.toggle()
        },
        async eliminar(){
            try {
                this.$store.commit('promociones/promociones/set_id_promocion', null)

                const {data} = await Promocion.eliminarPromocion(this.detalle.id)
                this.notificacion('Mensaje', 'Eliminó exitosamente', 'success')
                this.$store.dispatch('promociones/promociones/action_listar_promociones')
                this.$refs.modalEliminarPromo.toggle()
                this.limpiarPromocion()

            } catch (e){
                this.error_catch(e)
            }

        },
        async actualizarDetalle(){
            const enviar = {
                id_promocion: this.detalle.id,
                id_tienda: null
            }
            this.loading = true
            await this.$store.dispatch('promociones/promociones/action_listar_detalle_promocion',enviar )
            this.loading = false

        },
        limpiarPromocion(){
            const datos ={
                id: null,
                nombre: '',
                imagen: null,
                imagen_show: null,
                tipo: '',
                condiciones: '',
                categorias:1,
                visible: 1,
                promo_categorias:[]
            }
            this.$store.commit('promociones/promociones/set_detalle', datos)
            this.$store.commit('promociones/promociones/set_cedis', [])
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 92px);
}
</style>
